import { DOCUMENT, NgStyle } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import { NewFeatureModalModel } from '@boels-shared/components/new-feature-modal/models/new-feature-modal.model';
import { GLOBAL_CLASS_NO_SCROLL_ALLOWED } from '@boels-shared/constants/global.const';
import { TrustHtmlPipe } from '../../pipes/trust-html.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatChipSet, MatChip } from '@angular/material/chips';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
  selector: 'boels-new-feature-modal',
  templateUrl: './boels-new-feature-modal.component.html',
  styleUrls: ['./boels-new-feature-modal.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatChipSet,
    MatChip,
    NgStyle,
    MatDialogActions,
    TranslateModule,
    TrustHtmlPipe,
  ],
})
export class BoelsNewFeatureModalComponent implements OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: NewFeatureModalModel,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly dialogRef: MatDialogRef<BoelsNewFeatureModalComponent>
  ) {
    this.document.documentElement.classList.add(GLOBAL_CLASS_NO_SCROLL_ALLOWED);
  }

  public ngOnDestroy(): void {
    this.document.documentElement.classList.remove(GLOBAL_CLASS_NO_SCROLL_ALLOWED);
  }

  /**
   * Closes the modal and writes the state to local storage so next time this modal won't be shown.
   */
  public closeModal(): void {
    localStorage.setItem(this.data?.storageKey, JSON.stringify(true));
    this.dialogRef.close();
  }
}
