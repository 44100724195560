import * as Sentry from '@sentry/angular';
import { APP_INITIALIZER, EnvironmentProviders, ErrorHandler, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';

Sentry.init({
  ...environment.sentry,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
});

export const provideSentry = (): Array<Provider | EnvironmentProviders> => [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler(),
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => {},
    deps: [Sentry.TraceService],
    multi: true,
  },
];
