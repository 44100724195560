import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, createUrlTreeFromSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { DebtorState } from '../../state/state/debtors.state';

@Injectable({
  providedIn: 'root',
})
export class DetailsPageGuard {
  constructor(
    protected store: Store,
    protected router: Router
  ) {}

  /**
   * This guard is used only for details pages, if debtorNumber in query param
   * isn't in the state than we don't allow user to view
   * the page and redirect back to it's parent page.
   * @param activatedRouteSnapshot
   */
  public canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    const queryParamDebtorIncludedInStore: boolean = this.store
      .selectSnapshot(DebtorState.getActiveDebtorNumbers)
      ?.includes(activatedRouteSnapshot.queryParams['debtorNumber']);

    // If debtor query param isn't in the list of debtor in the state,
    // we redirect user back to the parent view.
    if (!queryParamDebtorIncludedInStore) {
      this.router.navigate([createUrlTreeFromSnapshot(activatedRouteSnapshot, ['../']).toString()]);
    }

    return of(
      this.store
        .selectSnapshot(DebtorState.getActiveDebtorNumbers)
        ?.includes(activatedRouteSnapshot.queryParams['debtorNumber'])
    );
  }
}
