import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormatPhonePipe } from '@boels-experience/phone';
import { CountryCode } from 'libphonenumber-js';
import { QaHooksDirective } from '@boels-experience/core';

@Component({
  selector: 'boels-direct-hire',
  standalone: true,
  imports: [FormatPhonePipe, QaHooksDirective],
  templateUrl: './cms-direct-hire.component.html',
  styleUrl: './cms-direct-hire.component.scss',
})
export class CmsDirectHireComponent implements OnChanges {
  @Input() public intro!: string;
  @Input() public phoneNumber!: string;
  @Input() public phoneSuffix!: string;
  @Input() public countryCode?: string;

  public _countryCode: CountryCode;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['countryCode']) {
      this._countryCode = this.countryCode.toUpperCase() as unknown as CountryCode;
    }
  }
}
