import { Component, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCheckboxChange, MatCheckbox } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogClose,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import { Observable, map } from 'rxjs';
import { CheckBoxDebtor, Debtor, DebtorDialogData } from '@boels-core/models/user.model';
import { BreakpointService } from '@boels-core/services/breakpoint.service';
import { DestroyReferenceDirective } from '@boels-shared/directives/destroy-reference.directive';
import { TopCheckboxModel } from '@boels-shared/models/top-checkbox.model';
import { ModalsService } from '@boels-shared/services/modals.service';
import { TranslateModule } from '@ngx-translate/core';
import { QaHooksDirective } from '@boels-experience/core';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgClass, AsyncPipe } from '@angular/common';

@Component({
  selector: 'boels-dialog-selected-debtor',
  templateUrl: './selected-debtor-dialog.component.html',
  styleUrls: ['./selected-debtor-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogClose,
    CdkScrollable,
    MatDialogContent,
    NgClass,
    MatCheckbox,
    QaHooksDirective,
    MatDialogActions,
    AsyncPipe,
    TranslateModule,
  ],
})
export class BoelsSelectedDebtorDialogComponent extends DestroyReferenceDirective {
  public activeAccounts: Array<Debtor> = [];
  public currentAccount$: Observable<Array<CheckBoxDebtor>> = this.data.accounts$.pipe(
    map((data) => {
      return data.map((account) => {
        return {
          ...account,
          isChecked: !!this.data.activeAccounts?.find((active) => active.debtorNumber === account.debtorNumber),
        };
      });
    })
  );
  public isCheckedAtLeastOneAccount: boolean = true;
  public isChanged: boolean = false;
  public accounts: Array<CheckBoxDebtor> = [];
  public isMobile$: Observable<boolean> = this.breakpointService.isMobile$;

  public checkboxToggleState: TopCheckboxModel = {
    areAllChecked: null,
    partiallyChecked: null,
  };

  constructor(
    private readonly breakpointService: BreakpointService,
    @Inject(MAT_DIALOG_DATA)
    public data: DebtorDialogData,
    private readonly dialogRef: MatDialogRef<BoelsSelectedDebtorDialogComponent>,
    private readonly modalsService: ModalsService
  ) {
    super();

    this.activeAccounts = this.data.activeAccounts;

    this.currentAccount$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => (this.accounts = res));

    this.setCheckboxToggleState();
  }

  public onSelect(item: CheckBoxDebtor, event: MatCheckboxChange): void {
    item.isChecked = !item.isChecked;
    this.isCheckedAtLeastOneAccount = !!this.accounts.find((account: CheckBoxDebtor) => account.isChecked);
    this.activeAccounts = this.accounts
      .filter((checkbox: CheckBoxDebtor) => checkbox.isChecked)
      .map((account: CheckBoxDebtor) => ({ name: account.name, debtorNumber: account.debtorNumber }));

    this.isChanged = JSON.stringify(this.activeAccounts) !== JSON.stringify(this.data.activeAccounts);

    this.setCheckboxToggleState();
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection.
   */
  public rowsSelectedToggle(): void {
    const getCheckedAccounts = this.returnCheckedDebtors();
    this.accounts.forEach(
      (checkbox: CheckBoxDebtor) => (checkbox.isChecked = getCheckedAccounts?.length !== this.accounts?.length)
    );

    this.isCheckedAtLeastOneAccount = !!this.accounts.find((account: CheckBoxDebtor) => account.isChecked);
    this.activeAccounts = this.accounts
      .filter((checkbox: CheckBoxDebtor) => checkbox.isChecked)
      .map((account: CheckBoxDebtor) => ({ name: account.name, debtorNumber: account.debtorNumber }));

    this.isChanged = JSON.stringify(this.activeAccounts) !== JSON.stringify(this.data.activeAccounts);

    this.setCheckboxToggleState();
  }

  /**
   * Listens to changes within debtors array and calculates
   * if top checkbox should shown checkmark or intermediate state.
   * @private
   */
  private setCheckboxToggleState(): void {
    const getCheckedAccounts = this.returnCheckedDebtors();

    this.checkboxToggleState.areAllChecked = getCheckedAccounts?.length === this.accounts?.length;
    this.checkboxToggleState.partiallyChecked =
      getCheckedAccounts?.length > 0 && getCheckedAccounts?.length !== this.accounts?.length;
  }

  /**
   * Returns checked debtors from form array controls.
   * @private
   */
  private returnCheckedDebtors(): Array<Debtor> {
    return this.accounts?.filter((checkbox) => checkbox.isChecked);
  }

  /**
   * Closes the modal, or if form is dirty and not
   * in final state opens confirmation close modal.
   */
  public async closeModal(): Promise<void> {
    if (this.isChanged) {
      const result = await this.modalsService.openDiscardModal();
      if (result) {
        this.dialogRef.close();
      }
      return;
    }

    this.dialogRef.close();
  }
}
